<template>
    
    <div class="grid">
        
        <div class="col-12">
            <div class="card">
                
                <div class="flex">
                    <div class="col-12 lg:col-6  sm:col-12 md:col-12 mb-2">
                    <label>EAFs</label>
                    
                    <Dropdown v-model="form.add_app_id" optionValue="add_app_id" :options="selectedClass" optionLabel="class_exam_name" @change="selectAddAppNumFetch" placeholder="Select an EAF" class="full-width" :filter="true" >
                        <template v-slot:option="{ option }">
                            <div>
                               
                                {{ option.class_name }} - {{ option.exam_name }}
                            </div>
                        </template>
                    </Dropdown>
                    <HasError class="p-error" :form="form" field="add_app_id" />
                    </div>
                    <div class="col-12 lg:col-6  sm:col-12 md:col-12 mb-2" v-for="(rec, index) in records" :key="rec.id">
                        <div class="card" v-if="index === 0">
                            <div class="grid" >
                                <div class="col-6 lg:col-4 sm:col-6 md:col-6 mb-2">
                                    <span><strong>Program:</strong> {{ rec.program }}</span><br /><br />
                                    <span><strong>Prof.Year:</strong> {{ getProfYearLabel(rec.prof_year) }}</span>
                                </div>
                                <div class="col-6 lg:col-8 sm:col-6 md:col-6 mb-2">
                                    <span><strong>Institute:</strong> {{ rec.institute }}</span><br /><br />
                                    <span><strong>Annual / Supplementary:</strong> {{ rec.annual_or_supply }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <TabView>
                    <TabPanel header="Submitted EAFs">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <h3>Submitted {{ module_name }}</h3>
                            </template>
                           
                        </Toolbar>
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                               <Button  v-tooltip.top="'Export'" icon="pi pi-external-link" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-warning mr-2" :label="showBtnLabels ? 'Export' : ''" @click="exportCSV($event)" />
                                <Button  v-tooltip.top="'Download Forms'" icon="pi pi-cloud-download" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-primary mr-2" :label="showBtnLabels ? 'Download Forms' : ''" @click="getBulkPDFs($event)" />
                               
                            </template> 
                        </Toolbar> 
                        
                        <DataTable :resizableColumns="true" columnResizeMode="fit" ref="dt" :value="records" :paginator="true" sortMode="multiple" class="p-datatable-gridlines p-datatable-sm"  :rows="10" dataKey="id" :rowHover="true" 
                            v-model:filters="filters" filterDisplay="menu" :loading="loading" :filters="filters" responsiveLayout="scroll"
                            :rowsPerPageOptions="[10,20,50,100]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" v-model:selection="selectedRecords" 
                            :scrollable="true" scrollHeight="400px">
                           
                            <template #header>
                                <div class="flex justify-content-between flex-column sm:flex-row">  
                                    <span class="p-input-icon-left mb-2">
                                        <i class="pi pi-search" />
                                        <InputText v-model="filters['global'].value" placeholder="Keyword Search" style="width: 100%"/>
                                    </span>
                                    <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header" @update:modelValue="onToggle"
                                        placeholder="Select Columns" style="width: 20em"/>
                                </div>
                            </template>
                            
                            <template #empty> 
                                <div class="table-message"> 
                                    <img class="table-message-img" src="layout/images/no-record-found.png" alt="No record found" >
                                </div>
                            </template>
                            <template #loading>
                                Loading data. Please wait.
                            </template> 
                            
                            <Column selectionMode="multiple" style="max-width:3rem;flex-grow:1; flex-basis:160px"></Column>
                            <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header" 
                                :exportable="col.field == 'action' ? false:true" :sortable="col.field == 'action' ? false:true" 
                                :key="col.field + '_' + index" :class="col.field == 'action' ? 'btnAction':''" 
                                :style="col.field !== 'action' ? 'min-width:12rem;flex-grow:1; flex-basis:160px;':'max-width:5rem;flex-grow:1; flex-basis:160px;'"> 
                              
                               
                                <template  #body="{data}">
                                   
                                    <span v-if="col.field == 'download'"><Button @click="getPDF(data.id,data.roll_no,data.name,formatDate(data.created_at))" icon="pi pi-cloud-download" /></span>
                                    <!-- <span v-if="col.field == 'id'">{{ data.id }}</span> data.student_id-->
                                    <span v-if="col.field == 'roll_no'">{{ data.roll_no }}</span>
                                   <img  v-if="col.field == 'name' && data.picture" :alt="data.name" :src="'https://fs.cmhlmc.com/storage/students/files/students/'+data.student_id+'/'+data.picture" class="tableProfileImg mr-2" />
                                    <img  v-if="col.field == 'name' && !data.picture" :alt="data.name" :src="filesUrl+'defaults/default.png'" class="tableProfileImg mr-2" />
                                    <span v-if="col.field == 'name'" @click="selectedRecordId = data.id;
                                    selectedData = JSON.stringify(data);
                                    ">{{ data.name }}</span>
                                           
                                    <span v-if="col.field == 'father_name'">{{ data.father_name }}</span>
                                    <span v-if="col.field == 'nums_reg_no'">{{ data.nums_reg_no }}</span>
                                    <span v-if="col.field == 'nationality'">{{ data.nationality }}</span>
                                    <span v-if="col.field == 'created_at'">{{ formatDate(data.created_at) }}</span>
                                
                                    <span v-if="col.field == 'action'" @click="selectedRecordId = data.id;selectedData = JSON.stringify(data);">
                                        <div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
                                            <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text" :selectedData="JSON.stringify(data)" :recordId="data.id" @click="toggleRecordMenu"></Button>
                                            <Menu ref="menu" :popup="true" :model="actions" ></Menu>
                                        </div> 
                                    </span> 
                                    
                                </template>
                               
                                 <template #filter="{filterModel}">
                                    <span v-if="col.field == 'name'"> 
                                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                                    </span>   
                                   
                                    <span v-else-if="col.field == 'status'">
                                        <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Any" class="p-column-filter" :showClear="true">
                                            <template #value="slotProps">
                                                <span :class="'customer-badge status-' + slotProps.value" v-if="slotProps.value">{{slotProps.value}}</span>
                                                <span v-else>{{slotProps.placeholder}}</span>
                                            </template>
                                            <template #option="slotProps">
                                                <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                                            </template>
                                        </Dropdown>
                                    </span>  
                                </template>    
                            </Column>   
                        </DataTable>
                    </TabPanel>
                    <TabPanel header="Pending EAFs">
                       
                                <Toolbar class="pl-0 pr-0">
                                    <template v-slot:start>
                                        <h3>Pending {{ module_name }}</h3>
                                    </template>
                                   
                                </Toolbar>
                                <Toolbar class="pl-0 pr-0">
                                    <template v-slot:start>
                                        <!-- <Button  v-tooltip.top="'Refresh'" :label="showBtnLabels ? 'Refresh' : ''" icon="pi pi-refresh" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-success mr-2"   @click="getRecordsT2(selectedAddAppNum)" />  -->
                                        <Button  v-tooltip.top="'Export'" icon="pi pi-external-link" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-warning mr-2" :label="showBtnLabels ? 'Export' : ''" @click="exportCSVT2($event)" />
                                    </template> 
                                </Toolbar> 
                                
                                <!-- <DataTable :resizableColumns="true" columnResizeMode="fit" ref="dt_2" :value="records_pending" :paginator="true" sortMode="multiple" class="p-datatable-gridlines p-datatable-sm"  :rows="10" dataKey="id" :rowHover="true" 
                                    v-model:filters="filters_t2" filterDisplay="menu" :loading="loading_t2" :filters="filters_t2" responsiveLayout="scroll"
                                    :globalFilterFields="['name','added_by','modified_by','date_added','status']" :rowsPerPageOptions="[10,20,50,100]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                    :scrollable="true" scrollHeight="400px"> -->
                                    <DataTable :resizableColumns="true" columnResizeMode="fit" ref="dt_2" :value="records_pending" :paginator="true" sortMode="multiple" class="p-datatable-gridlines p-datatable-sm"  :rows="10" dataKey="id" :rowHover="true" 
                                    :loading="loading_t2" :filters="filters_t2" responsiveLayout="scroll"
                                    :rowsPerPageOptions="[10,20,50,100]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                    :scrollable="true" scrollHeight="400px">
                                   
                                    <template #header>
                                        <div class="flex justify-content-between flex-column sm:flex-row">  
                                            <span class="p-input-icon-left mb-2">
                                                <i class="pi pi-search" />
                                                <InputText v-model="filters_t2['global'].value" placeholder="Keyword Search" style="width: 100%"/>
                                            </span>
                                            <!-- <MultiSelect :modelValue="selectedColumnsPending" :options="columns_pending" optionLabel="header" @update:modelValue="onToggle"
                                                placeholder="Select Columns" style="width: 20em"/> -->
                                        </div>
                                    </template>
                                    
                                    <template #empty> 
                                        <div class="table-message"> 
                                            <img class="table-message-img" src="layout/images/no-record-found.png" alt="No record found" >
                                        </div>
                                    </template>
                                    <template #loading_t2>
                                        Loading data. Please wait.
                                    </template> 
                                    
                                
                                    <Column v-for="(col, index) of selectedColumnsPending" :field="col.field" :header="col.header" 
                                        :exportable="col.field == 'action' ? false:true" :sortable="col.field == 'action' ? false:true" 
                                        :key="col.field + '_' + index" :class="col.field == 'action' ? 'btnAction':''" 
                                        :style="col.field !== 'action' ? 'min-width:12rem;flex-grow:1; flex-basis:160px;':'max-width:5rem;flex-grow:1; flex-basis:160px;'"> 
                                      
                                        <template  #body="{data}">

                                            <span v-if="col.field == 'roll_no'">{{ data.system_rollno_prefix }}-{{ data.system_rollno }}</span>
                                            <span v-if="col.field == 'name'" >{{ data.first_name + ' '+ data.last_name }}</span>
                                            <span v-if="col.field == 'father_name'">{{ data.father_name }}</span>
                                            <span v-if="col.field == 'nums_reg_no'">{{ data.nums_reg_no }}</span>
                                            
                                        </template>
                                       
                                         <template #filter="{filterModel}">
                                            <span v-if="col.field == 'name'"> 
                                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                                            </span>   
                                           
                                            <span v-else-if="col.field == 'status'">
                                                <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Any" class="p-column-filter" :showClear="true">
                                                    <template #value="slotProps">
                                                        <span :class="'customer-badge status-' + slotProps.value" v-if="slotProps.value">{{slotProps.value}}</span>
                                                        <span v-else>{{slotProps.placeholder}}</span>
                                                    </template>
                                                    <template #option="slotProps">
                                                        <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                                                    </template>
                                                </Dropdown>
                                            </span>  
                                        </template>    
                                    </Column>   
                                </DataTable>
                          
                    </TabPanel>
                    <TabPanel header="Pending by Student Affairs">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <h3>Applications Not Approved by Student Affairs</h3>
                            </template>
                           
                        </Toolbar>
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                               <Button  v-tooltip.top="'Export'" icon="pi pi-external-link" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-warning mr-2" :label="showBtnLabels ? 'Export' : ''" @click="exportCSV($event)" />
                               
                            </template> 
                        </Toolbar> 
                        
                        <DataTable :resizableColumns="true" columnResizeMode="fit" ref="dt" :value="records_not_approved" :paginator="true" sortMode="multiple" class="p-datatable-gridlines p-datatable-sm"  :rows="10" dataKey="id" :rowHover="true" 
                             :loading="loading" :filters="filters" responsiveLayout="scroll"
                            :rowsPerPageOptions="[10,20,50,100]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            :scrollable="true" scrollHeight="400px">
                           
                            <template #header>
                                <div class="flex justify-content-between flex-column sm:flex-row">  
                                    <span class="p-input-icon-left mb-2">
                                        <i class="pi pi-search" />
                                        <InputText v-model="filters['global'].value" placeholder="Keyword Search" style="width: 100%"/>
                                    </span>
                                  
                                </div>
                            </template>
                            
                            <template #empty> 
                                <div class="table-message"> 
                                    <img class="table-message-img" src="layout/images/no-record-found.png" alt="No record found" >
                                </div>
                            </template>
                            <template #loading>
                                Loading data. Please wait.
                            </template> 
                            
                            
                            <Column v-for="(col, index) of selectedColumnsNotApproved" :field="col.field" :header="col.header" 
                                :exportable="col.field == 'action' ? false:true" :sortable="col.field == 'action' ? false:true" 
                                :key="col.field + '_' + index" :class="col.field == 'action' ? 'btnAction':''" 
                                :style="col.field !== 'action' ? 'min-width:12rem;flex-grow:1; flex-basis:160px;':'max-width:5rem;flex-grow:1; flex-basis:160px;'"> 
                              
                               
                                <template  #body="{data}">
                                   
                                    <!-- <span v-if="col.field == 'download'"><Button @click="getPDF(data.id,data.student_id,data.name,formatDate(data.created_at))" icon="pi pi-cloud-download" /></span> -->
                                    <!-- <span v-if="col.field == 'id'">{{ data.id }}</span> -->
                                    <span v-if="col.field == 'roll_no'">{{ data.roll_no }}</span>

                                   <img  v-if="col.field == 'name' && data.picture" :alt="data.name" :src="'https://fs.cmhlmc.com/storage/students/files/students/'+data.student_id+'/'+data.picture" class="tableProfileImg mr-2" />
                                    <img  v-if="col.field == 'name' && !data.picture" :alt="data.name" :src="filesUrl+'defaults/default.png'" class="tableProfileImg mr-2" />
                                    <span v-if="col.field == 'name'" @click="selectedRecordId = data.id;
                                    selectedData = JSON.stringify(data);
                                    ">{{ data.name }}</span>
                                    <span v-if="col.field == 'father_name'">{{ data.father_name }}</span>
                                    <span v-if="col.field == 'verified_by_smp'"><Tag :value="data.verified_by_smp == 0 ? 'Pending' : 'Rejected'" :severity="getSeverity(data.verified_by_smp) " /></span>
                                    <span v-if="col.field == 'nums_reg_no'">{{ data.nums_reg_no }}</span>
                                    <span v-if="col.field == 'nationality'">{{ data.nationality }}</span>
                                 
                                    <span v-if="col.field == 'action'" @click="selectedRecordId = data.id;selectedData = JSON.stringify(data);">
                                        <div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
                                            <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text" :selectedData="JSON.stringify(data)" :recordId="data.id" @click="toggleRecordMenu"></Button>
                                            <Menu ref="menu" :popup="true" :model="actions" ></Menu>
                                        </div> 
                                    </span> 
                                    
                                </template>
                               
                                 <template #filter="{filterModel}">
                                    <span v-if="col.field == 'name'"> 
                                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                                    </span>   
                                   
                                    <span v-else-if="col.field == 'status'">
                                        <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Any" class="p-column-filter" :showClear="true">
                                            <template #value="slotProps">
                                                <span :class="'customer-badge status-' + slotProps.value" v-if="slotProps.value">{{slotProps.value}}</span>
                                                <span v-else>{{slotProps.placeholder}}</span>
                                            </template>
                                            <template #option="slotProps">
                                                <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                                            </template>
                                        </Dropdown>
                                    </span>  
                                </template>    
                            </Column>   
                        </DataTable>
                    </TabPanel>
                    
                </TabView>
                

            </div>
        </div>
    
        <Toast />
        <StudentApplicationsForm v-on:formClose="closeForm" v-if="showForm" :selectedData="selectedData" :recordId="selectedRecordId" :showForm="true" />
     
    </div>
</template>
<script>
import StudentApplicationsForm from './Modals/StudentApplicationsForm.vue';

import { FilterMatchMode, FilterOperator } from 'primevue/api';
export default {
    data() {
        return {
            module_name: 'EAFs Applications',
            actions: [],
            showHelp: false,
            isSaving: false,
            records: null,
            records_pending: null,
            records_not_approved: null,
            filters: null,
            filters_t2: null,
            loading: false,
            loading_t2: false,
            selectedRecords: null,
            columns: null,
            columns_pending: null,
            columns_not_approved: null,
            selectedColumns: null,
            selectedColumnsPending: null,
            selectedColumnsNotApproved: null,
            showForm: false,
            showView: false,
            selectedRecordId: 0,
            selectedPendingRecordId: 0,
            saveAndClose: false,
            selectedData: '',
            isDisabled: true,
            AddAppNums: [],
            selectedAddAppNum: null,
            selectedCity: null,
            form: new this.Form({
                add_app_id: '',
                
            }),
            selectedClass: [],
            
        };
    },
    components: {
        StudentApplicationsForm,
    },
    created() {
        this.actions.push({
            label: 'View',
            icon: 'pi pi-eye',
            command: () => {
                this.getClickedRecord('view');
            },
        });

        this.actions.push({
            label: 'Change Subjects',
            icon: 'pi pi-refresh',
            command: () => {
                this.getClickedRecord('update');
            },
        });

        this.actions.push({
            label: 'Download',
            icon: 'pi pi-download',
            command: () => {
                this.getClickedRecord('download');
            },
        });

        this.initFilters();
        this.columns = [
                {field: 'action', header: 'Action'},
                // {field: 'id', header: 'Application Id'},
                {field: 'roll_no', header: 'Roll No'}, 
                {field: 'name', header: 'Name'},
                {field: 'father_name', header: 'Father Name'}, 
                {field: 'nums_reg_no', header: 'Registration Number'},    
                {field: 'nationality', header: 'Nationality'},    
                {field: 'created_at', header: 'Submission Date'},    
               
            ];
            this.selectedColumns = this.columns;
            this.columns_pending = [
                {field: 'roll_no', header: 'Roll No'},
                {field: 'name', header: 'Name'}, 
                {field: 'father_name', header: 'Father Name'}, 
                {field: 'nums_reg_no', header: 'Registration Number'},    
               
            ];
            this.selectedColumnsPending = this.columns_pending;
            this.columns_not_approved = [
                {field: 'roll_no', header: 'Roll No'}, 
                {field: 'name', header: 'Name'}, 
                {field: 'father_name', header: 'Father Name'}, 
                {field: 'verified_by_smp', header: 'Status'}, 
                {field: 'nums_reg_no', header: 'Registration Number'},    
                {field: 'nationality', header: 'Nationality'},    
                
            ];
            this.selectedColumnsNotApproved = this.columns_not_approved;
    },
    
    computed: {},
    mounted() {
        //this.getAddAppNums();
        this.getAllClasses();
    },
    methods: {
        selectAddAppNumFetch(){
            this.isDisabled = false;
            this.getRecords(this.form.add_app_id);
        },
        getAllClasses() {
            var Vthis = this;
            // this.loading = true;
            this.axios
                .post('api/get-admission-application-nums-dropdown')
                .then((response) => {
                    this.selectedClass = response.data;
                })
                .catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                })
                .finally(() => {});
        },
        // addNewRecord() {
        //     this.selectedData = null;
        //     this.selectedRecordId = 0;
        //     this.selectedPendingRecordId = 0;
        //     this.showForm = true;
        // },
        getClickedRecord(action) {
            if (action == 'view') {
                this.getPDFView();
            } 
            else if (action == 'update') {
                this.showForm = true;
               
            }
            else if (action == 'download') {
                this.getPDF();
            }
        },
        toggleRecordMenu(event) {
            this.selectedRecordId = event.currentTarget.getAttribute('recordId');
            this.selectedPendingRecordId = event.currentTarget.getAttribute('pendingRecordId');
            this.selectedData = event.currentTarget.getAttribute('selectedData');
            this.$refs.menu.toggle(event);
        },
        loadForm() {
            this.selectedRecordId = 0;
            this.selectedPendingRecordId = 0;
            this.showForm = true;
        },
        closeForm() {
            // console.log(this.selectedSubject)
            this.getRecords(this.selectedAddAppNum);
            this.showForm = false;
            //this.showView = false;
        },
        getRecords() {
            var Vthis = this;
            this.loading = true;
            this.selectedAddAppNum = this.form.add_app_id;
            this.axios
                .post('api/get-student-applications', { add_app_id: this.form.add_app_id })
                .then((response) => {
                    this.records = response.data;
                    this.loading = false;
                })
                .catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                })
                .finally(() => {});
            this.getRecordsPending();
            this.getRecordsStudAffRemaining();
        },
        getRecordsStudAffRemaining() {
            var Vthis = this;
            this.loading = true;
            this.axios
                .post('api/get-student-applications-not-approved', { add_app_id: this.form.add_app_id })
                .then((response) => {
                    this.records_not_approved = response.data;
                    this.loading = false;
                })
                .catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                })
                .finally(() => {});

        },
        getRecordsPending() {
            var Vthis = this;
            this.loading_t2 = true;
            this.axios
                .post('api/get-pending-student-applications', { add_app_id: this.form.add_app_id })
                .then((response) => {
                    this.records_pending = response.data;
                    this.loading_t2 = false;
                })
                .catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                })
                .finally(() => {});
        },
       
        getAddAppNums() {
            var Vthis = this;

            this.axios
                .post('api/get-admission-application-nums')
                .then((response) => {
                    this.AddAppNums = response.data;
                })
                .catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                })
                .finally(() => {});
        },

        

        getPDF() {
                this.loading = true;
                console.log(this.selectedData);
                const data = JSON.parse(this.selectedData);
                const options = { year: 'numeric', month: 'long', day: 'numeric' };
                 
                // Extract the desired values
                const id = data.id;
                const roll_no = data.roll_no;
                const name = data.name;
                const date = new Date(data.created_at).toLocaleDateString(undefined, options);

                this.axios.get('api/get-pdf', {
                    params: { 
                        status: this.getStatus,
                        id: id,
                    },
                    responseType: 'blob',
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    // Create a blob from the response data
                    const blob = new Blob([response.data], { type: 'application/pdf' });

                    // Create a URL for the blob
                    const url = window.URL.createObjectURL(blob);

                    // Create a link element to trigger the download
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = "NUMS_Form_"+name+"_"+roll_no+"_"+date+'.pdf'; // Set the desired file name

                    // Trigger the click event on the link to start the download
                    link.click();

                    // Clean up
                    window.URL.revokeObjectURL(url);
                    this.loading = false;
                })
                .catch((error) => {
                    let message = this.customError(error.response);
                    this.showMessage(message, 'error');
                    this.loading = false;
                });
            },
            getPDFView() {
                this.loading = true;
                console.log(this.selectedData);
                const data = JSON.parse(this.selectedData);
                const options = { year: 'numeric', month: 'long', day: 'numeric' };
                 
                // Extract the desired values
                const id = data.id;
                const stu_id = data.student_id;
                const name = data.name;
                const date = new Date(data.created_at).toLocaleDateString(undefined, options);

                this.axios.get('api/get-pdf-view', {
                    params: { 
                        status: this.getStatus,
                        id: id,
                    },
                    responseType: 'blob',
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    // Create a blob from the response data
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    const url = window.URL.createObjectURL(blob);
                    //console.log(response.data);
                    // Open the PDF in a new window/tab
                    window.open(url, '_blank');

                    this.loading = false;
                })
                .catch((error) => {
                    let message = this.customError(error.response);
                    this.showMessage(message, 'error');
                    this.loading = false;
                });
            },

            getBulkPDFs() {
            this.loading = true;
            //console.log(this.selectedRecords);

            // Extract the 'id' values from selectedRecords
            const ids = this.selectedRecords.map((record) => record.id);

            //console.log(ids);

           
            this.axios.post('api/get-pdfs', { ids: ids }, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                // Create a blob from the response data
                const blob = new Blob([response.data], { type: 'application/zip' });

                // Create a URL for the blob
                const url = window.URL.createObjectURL(blob);

                // Create a link element to trigger the download
                const link = document.createElement('a');
                link.href = url;
                link.download = "NUMS_Forms.zip"; // Set the desired file name

                // Trigger the click event on the link to start the download
                link.click();

                // Clean up
                window.URL.revokeObjectURL(url);
                this.loading = false;
            })
            .catch((error) => {
                let message = this.customError(error.response);
                this.showMessage(message, 'error');
                this.loading = false;
            });
            },
            
            exportCSV() {
                this.$refs.dt.exportCSV();
            },
            exportCSVT2() {
                this.$refs.dt_2.exportCSV();
            },
            onToggle(value) {
                this.selectedColumns = this.columns.filter(col => value.includes(col));
               // this.selectedColumnsPending = this.columns_pending.filter(col => value.includes(col));
            },
            
            initFilters() {
				this.filters = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'added_by': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'modified_by': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'date_added': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]}, 
					'date_modification': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]}, 
					'status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]}, 
				};
                this.filters_t2 = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'added_by': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'modified_by': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'date_added': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]}, 
					'date_modification': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]}, 
					'status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]}, 
				}
			},
			clearFilter() {
				this.initFilters();
                this.getStatus=0;
                this.getRecords();  
			}, 
            isRTL() {
				return this.$appState.RTL;
			},
            getProfYearLabel(profYear) {
            if (profYear == 1) {
                return '1st Prof Year';
            } else if (profYear == 2) {
                return '2nd Prof Year';
            } else if (profYear == 3) {
                return '3rd Prof Year';
            } else if (profYear == 4) {
                return `${profYear}th Prof Year`;
            } else if (profYear == 5) {
                return `Final Prof Year`;
            } else {
                return `${profYear}`; // Handle other cases if needed
            }
            },
            getSeverity(status) {
            switch (status) {
                case 0:
                    return 'info';
                case 1:
                    return 'success';

                case 2:
                    return 'danger';
               

                default:
                    return null;
            }
            }
            
    },
    watch: {},
};
</script>

 

<style scoped>
::v-deep(b) {
    display: block;
}

::v-deep(.p-card-body) {
    padding: 2rem;
}
</style>
